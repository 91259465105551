
































































































import {Component, Prop, Vue} from "vue-property-decorator";
import FileUpload from "@/components/common/FileUpload.vue";
import DocumentService from "@/services/DocumentService";
import CompanyDTO from "@/dto/company/CompanyDTO";
import Workspaces from "@/state/Workspaces";
import ReceiptDTO, {ReceiptStatus} from "@/dto/ReceiptDTO";
import {namespace} from "vuex-class";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");

interface ReceiptMode {
  tagMode: boolean;
  amountMode: boolean;
}

@Component({
  components: {PaginationComponent, SimpleCompanySearch, FileUpload},
  computed: {
    ReceiptStatus() {
      return ReceiptStatus;
    },
  },
  filters: {
    currency(value: number) {
      return `$${value.toFixed(2)}`;
    },
  },
})
export default class ReceiptsAdmin extends Vue {
  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Prop()
  private onFilesUploaded!: () => void;

  private company: CompanyDTO | null = null;

  private receipts: ReceiptDTO[] = [];

  private receiptModesByIds: Record<number, ReceiptMode> = {};

  private pageNumber = 1;

  private totalPages = 1;

  private companyId: number | null = null;

  private searchTag = "";
  private filteredTags: string[] = [];

  private defaultTags: string[] = [];

  private pageStatus: ReceiptStatus | null = null;

  private changePage(page: number): void {
    this.pageNumber = page;
    this.getFilesPage();
  }

  private selectCompany(company: CompanyDTO) {
    this.companyId = company.id;
    this.getFilesPage();
  }

  mounted() {
    this.getFilesPage();
    this.loadTags();
  }

  get getId() {
    return Workspaces.getCurrent.id;
  }

  getFilesPage() {
    this.startLoading();
    DocumentService.getReceiptsPage(
        this.pageNumber,
        20,
        this.pageStatus,
        this.companyId
    ).then(
        (response) => {
          this.receipts = response.data.content;
          this.receiptModesByIds = {};
          for (let r of response.data.content) {
            let amountMode = false;
            if (r.amount !== null) {
              amountMode = false;
            } else {
              amountMode = true;
            }
            this.$set(this.receiptModesByIds, r.id, {tagMode: false, amountMode});
          }
          this.totalPages = response.data.totalPages;
          this.stopLoading();
        },
        (error) => {
          this.stopLoading();
          console.error("Error fetching receipts:", error);
        }
    );
  }

  onChangeReceiptStatus(receiptId: number, receiptStatus: ReceiptStatus | null) {
    this.startLoading();
    if (receiptStatus != null) {
      DocumentService.changeReceiptStatus(receiptId, receiptStatus).then(
          () => {
            this.getFilesPage();
            this.stopLoading();
          },
          (err) => {
            console.log(err.message);
            this.stopLoading();
          }
      );
    }
  }

  onAddReceiptTags(receiptId: number, tag: string) {
    this.startLoading();
    DocumentService.addReceiptTags(receiptId, tag).then(
        () => {
          this.stopLoading();
          this.$set(this.receiptModesByIds[receiptId], "tagMode", false);
          this.getFilesPage();
        },
        (error) => {
          console.log(error.message);
          this.stopLoading();
        }
    );
  }

  onChangeReceiptAmount(receiptId: number, amount: number | null) {
    console.log("Changing receipt amount:", receiptId, amount);
    if (amount === null || isNaN(amount) || amount <= 0) {
      console.error("Invalid amount value:", amount);
      return;
    }

    this.startLoading();
    DocumentService.changeReceiptAmount(receiptId, amount).then(
        () => {
          console.log("Amount changed successfully");
          this.$set(this.receiptModesByIds[receiptId], "amountMode", false);
          this.stopLoading();
        },
        (error) => {
          console.log("Error changing amount:", error.message);
          this.stopLoading();
        }
    );
  }

  onEditAmountClick(receiptId: number) {
    console.log("Editing amount for receipt:", receiptId);
    this.$set(this.receiptModesByIds[receiptId], "amountMode", true);
  }

  onPlusClick(receiptId: number) {
    this.$set(this.receiptModesByIds[receiptId], "tagMode", true);
  }

  onCloseClick(receiptId: number) {
    this.$set(this.receiptModesByIds[receiptId], "tagMode", false);
  }

  togglePageStatus() {
    this.getFilesPage();
  }

  setCursorPointer(event: MouseEvent) {
    (event.target as HTMLElement).style.cursor = "pointer";
  }

  resetCursor(event: MouseEvent) {
    (event.target as HTMLElement).style.cursor = "default";
  }

  filterTags() {
    const search = this.searchTag.toLowerCase();
    this.filteredTags = this.defaultTags.filter((tag) =>
        tag.toLowerCase().includes(search)
    );
  }

  loadTags() {
    this.startLoading();
    DocumentService.getAllReceiptsTags().then(
        (response) => {
          this.defaultTags = response.data;
          this.filteredTags = response.data;
          this.stopLoading();
        },
        (error) => {
          console.log(error);
          this.stopLoading();
        }
    );
  }
}
