import CompanyDTO from "@/dto/company/CompanyDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

export default interface ReceiptDTO{
    id : number;

    company: CompanyDTO;

    status: ReceiptStatus;

    amount: number | null;

    file: FileMetaDTO

    assignee: SublimeEmployeeDTO

    tags: string[]

}

export enum ReceiptStatus{
    NEW = "NEW",
    PROCESSED = "PROCESSED"
}